import request from "@/utils/request";

//获取关于我们数据
export function getData(params) {
  return request({
    url: "/web/mch/official/about_us",
    method: "get",
    params: { ...params, mch_no: 375 },
  });
}
